import { bookingsUouExplorePlansClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BiServiceType, ReferralInfo } from '../../utils/bi/consts';
import { CatalogServiceDto } from '@wix/bookings-uou-types';
import {
  navigateToPricingPlan,
  navigateToPricingPlanPreview,
} from '@wix/bookings-catalog-calendar-viewer-navigation';

export type NavigateToPricingPlanAction = (
  service: CatalogServiceDto,
  referralInfo: ReferralInfo,
) => void;

export const createNavigateToPricingPlanAction =
  (
    wixSdkAdapter: WixOOISDKAdapter,
    flowApi: ControllerFlowAPI,
  ): NavigateToPricingPlanAction =>
  (service, referral) => {
    const planIds = service.pricingPlanInfo?.pricingPlans.map(
      (plan) => plan.id,
    );

    const subtitle = flowApi.translations.t(
      'navigation.pricing-plans.subtitle',
      {
        serviceName: service.info.name,
      },
    );

    if (flowApi.environment.isPreview) {
      return navigateToPricingPlanPreview({
        wixCodeApi: flowApi.controllerConfig.wixCodeApi,
        subtitle,
        isChangePricingPlanNavigationToNewApiEnabled:
          flowApi.experiments.enabled(
            'specs.bookings.ChangePricingPlanNavigationToNewApi',
          ),
      });
    }

    flowApi.bi?.report(
      bookingsUouExplorePlansClick({
        service_id: service.id,
        type: BiServiceType[service.type],
        isPendingApproval: service.schedulePolicy.isPendingApprovalFlow,
        referralInfo: referral,
      }),
    );
    return navigateToPricingPlan({
      wixCodeApi: flowApi.controllerConfig.wixCodeApi,
      redirectTo: {
        sectionId: 'Booking Calendar',
        relativePath: service.urlName,
      },
      planIds: planIds!,
      subtitle,
      queryParams: {
        referral,
        service: service.id,
      },
      isChangePricingPlanNavigationToNewApiEnabled: flowApi.experiments.enabled(
        'specs.bookings.ChangePricingPlanNavigationToNewApi',
      ),
    });
  };
